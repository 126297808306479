import imageUrlBuilder from "@sanity/image-url";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import React, { forwardRef, useCallback, useEffect } from "react";
import Background from "../components/Background";
import { configuredSanityClient } from "../components/Client";
import Content from "../components/content/Content";
import Seo from "../components/Seo";
import Title from "../components/Title";

const builder = imageUrlBuilder(configuredSanityClient);

function urlFor(source) {
	return builder.image(source);
}

const Information = forwardRef(({ data }, ref) => {
	const { _id, title, background, content } = data.page;
	return (
		<>
			<motion.div
				initial={{ opacity: 0, x: 0 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 1, x: 0 }}
				transition={{ duration: 1 }}
				className={"flex flex-col z-100 min-h-screen pt-14"}
				key={_id}
				ref={ref}
			>
				<Title title={title} />
				<Content contents={content} />
			</motion.div>
			{background && (
				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{
						duration: 1,
					}}
					className={"fixed w-screen h-screen top-0 -z-50"}
				>
					<Background background={background} />
				</motion.div>
			)}
		</>
	);
});

export default Information;

export const Head = ({ location, data }) => {
	const { page } = data;

	const bannerSeo = page.seoImage?.asset?._id
		? urlFor(page.seoImage.asset._id).width(1200).url()
		: null;

	return (
		<Seo
			title={page.seoTitle || page.title}
			description={page.seoDescription}
			banner={
				bannerSeo || urlFor(data.global.seoImage.asset._id).width(1200).url()
			}
			pathname={location.pathname}
		/>
	);
};

export const query = graphql`
	query getSingleInformation($slug: String) {
		page: sanityInformation(prefixedSlug: { current: { eq: $slug } }) {
			_id
			title
			background {
				... on SanityColour {
					_type
					colour
				}
				... on SanityTexture {
					_type
					image {
						asset {
							url
							metadata {
								dimensions {
									height
									width
								}
								palette {
									dominant {
										background
									}
								}
							}
						}
					}
				}
			}
			content {
				... on SanityModuleParagraph {
					_key
					_type
					_rawText
					centre
				}
				... on SanityModuleQuote {
					_key
					_type
					_rawQuote
					quoteDirection
				}
			}
			seoTitle
			seoDescription
			seoImage {
				asset {
					_id
				}
			}
		}
		global: sanityGlobal {
			seoImage {
				asset {
					_id
				}
			}
		}
	}
`;
